<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="1000px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        class="upload_box">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules"   label-width="230px">

                <el-form-item label="appId" prop="appId">
                    <el-input  v-model="ruleForm.appId"  />
                </el-form-item>


                <el-form-item label="群名+@+微信昵称" prop="wxid">
                    <el-input  v-model="ruleForm.wxid"  />
                </el-form-item>


                <el-form-item label="是否启用" prop="state">
                    <el-select v-model="ruleForm.state" style="width:100%" placeholder="请选择" @change="selectChange">
                        <el-option label="启用" :value="1" />
                        <el-option label="禁用" :value="2" />
                    </el-select>
                </el-form-item>
                
            </el-form>
        <!-- 表单结束 -->
        <!--操作按钮-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary"  @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
  
  </template>
  
  <script>
import { isNumber, isNumeric } from '@/utils/utils';
import ModelSet from '../orgPruduct/ModelSet.vue';
import Big from 'big.js';

  // 引入组件及方法
  // 引入组件
  export default {
    components: { ModelSet },
    // 数据
    data() {

      return {
        ruleForm:{
            appId:"",
            wxid:"",
            state:1,//状态：1 启用，2 禁用

        },
        id:null,
        isEdit:false,
        rules: {
            appId: [{ required: true, message: '不能为空', trigger: 'blur' }],
            wxid: [{ required: true, message: '不能为空', trigger: 'blur' }],

        },

      }
    },
    props:{
        visible:Boolean
    },
    model:{
        prop:'visible',
        event:'change'
    },
    watch: {
        visible(){
            if(this.visible){




            }
        }
    },
    //  初始化
    mounted() {
        
    },
  
    methods: {
        async start(obj, id){

            if(obj){
                this.ruleForm = obj
                this.isEdit = true
                this.id = id

            }else{
                this.isEdit = false
                this.ruleForm.appId = ''
                this.ruleForm.wxid = ''
                this.ruleForm.state = 1

                
            }
        },
        selectChange(){
            this.$forceUpdate()
        },
        handleClose(){
            // this.$emit('input', false)
            this.$emit('change', false)
        },
        handleSubmit(){
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    

                    var param = {
                        appId:this.ruleForm.appId,
                        wxid:this.ruleForm.wxid,
                        state:this.ruleForm.state
                    }

                    if(this.isEdit){
                        param.id = this.id

                        this.$https('updateConfig', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }else{
                        
                        
                        this.$https('addConfig', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                    


                    
                } else {
                    
                }
            });
        }

    }
  }
  </script>
  
  <style lang="scss" scoped>
  

  
  </style>
  