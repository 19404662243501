<template>
    <div>
        
        <el-card class="box-card">
            <el-button type="primary" @click="addBtnClick">添加</el-button>

        </el-card>
        <br>

        <el-table :data="tableData" style="width: 100%" border>

            <el-table-column
                prop="appId"
                label="appId">
            </el-table-column>


            <el-table-column
                prop="appName"
                label="应用名称">
            </el-table-column>

            <el-table-column
                prop="wxid"
                label="群名+@+微信昵称">
            </el-table-column>

            <el-table-column
                prop="wxidHash"
                label="wxid哈希值">
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="220">
                <template slot-scope="scope">

                    <el-button @click.stop="editBtn(scope.row)" type="text" size="small">
                        修改
                    </el-button>

                </template>
            </el-table-column>
            
        </el-table>

        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[20, 50, 100, 150]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>


        <EditWeeCharGroupDialog ref="editRef" v-model="editVisible" @ok="editDialogClose"></EditWeeCharGroupDialog>
    </div>
</template>
  
  
<script>
import EditWeeCharGroupDialog from './EditWeeCharGroupDialog.vue';


  
  export default {
    components: { EditWeeCharGroupDialog },
    data() {
      return {
        pageNumber:1,
        pageSize:20,
        total:0,
        tableData:[],
        editVisible:false,
        ttt:"1"
      };
    },
    async mounted() {


        this.getData()


    },
    methods: {
        getData() {
            

            this.$https('queryConfig', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
            }).then(res => {


                this.tableData = res.data.records
                this.total = parseInt(res.data.total)


            })
            
        },

        editBtn(item){
            this.editVisible = true
            this.$nextTick(()=>{
                this.$refs.editRef.start(item, item.id)

            })
        },

        addBtnClick(){
            this.editVisible = true
            this.$nextTick(()=>{
                this.$refs.editRef.start()

            })
        },

        editDialogClose(){
            this.getData()
        },

        handleSizeChange(size) {
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNumber = num
            this.getData()
        },

    },
  };
</script>
  
<style scoped lang="scss" >

</style>
  